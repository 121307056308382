import axios from "axios";
import { io } from "socket.io-client";

class WebSocketService {
    constructor() {
        this.socket = null;
    }
    connect(namespace) {
        this.socket = io(axios.defaults.baseURL + namespace);
    }
    on(event, result) {
        if (this.socket) {
            this.socket.on(event, result);
        }
    }
    off(event, result) {
        if (this.socket) {
            this.socket.off(event, result);
        }
    }
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }
}

export default new WebSocketService();
